import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { createStaticPix, hasError } from 'pix-utils';
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  FaCopy,
  FaHistory,
  FaMoneyBillWave,
  FaMoon,
  FaQrcode,
  FaRegCommentDots,
  FaSun,
  FaUser,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../../components/Footer';
import QRCodeModal from '../../components/Modal/QRCodeModal';
import useTheme from '../../hook/useTheme';
import { PixKeyTypes } from '../../types/pixKeyTypes';
import { maskCurrency, unMaskCurrency } from '../../utils/currency';
import { handlePixKeyChange } from '../../utils/validatedPixKey';

interface QRGeneratorProps {
  pixKey: string;
  merchantName: string;
  merchantCity: string;
  transactionAmount: string;
  infoAdicional: string;
  pixKeyType: string;
}

export const QRGenerator = () => {
  const { theme, setMode, mode } = useTheme();
  const [imgSrc, setImgSrc] = useState<string>('');
  const navigate = useNavigate();
  const [openQrcodeModal, setOpenQrcodeModal] = useState(false);
  const [maskKeys, setMaskKeys] = useState<{
    raw: string;
    masked?: string;
  }>({
    raw: '',
    masked: '',
  });

  const toggleMode = () => {
    localStorage.setItem('ColorMode', mode === 'light' ? 'dark' : 'light');
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const qrColor = localStorage.getItem('qrColor') || '#000000';

  const bgcolor = theme.palette.type === 'dark' ? '#282c34' : '#f2f2f2';
  const textColor = theme.palette.type === 'dark' ? '#f2f2f2' : '#000';

  useEffect(() => {
    const savedImg = localStorage.getItem('qrImage');
    if (savedImg) setImgSrc(savedImg);
  }, []);

  const { handleSubmit, control, setValue, getValues } =
    useForm<QRGeneratorProps>({
      defaultValues: {
        pixKeyType: 'CPF',
        pixKey: '',
        merchantName: '',
        transactionAmount: '',
        infoAdicional: '',
      },
    });

  const handleClose = () => {
    setOpenQrcodeModal(false);
  };

  const [qrValue, setQrValue] = useState('');
  const [showInput, setShowInput] = useState(true);

  const onSubmit = (value: QRGeneratorProps) => {
    const amount = unMaskCurrency(value.transactionAmount);
    const pix = createStaticPix({
      pixKey: value.pixKey,
      merchantName: value.merchantName,
      merchantCity: 'SAO PAULO',
      transactionAmount: amount,
      infoAdicional: value.infoAdicional,
    });

    if (!hasError(pix)) {
      const brCode = pix.toBRCode();
      navigator.clipboard.writeText(brCode);

      let storedQRCodes = localStorage.getItem('QRCodes');
      let qrcodesList = storedQRCodes ? JSON.parse(storedQRCodes) : [];
      let currentDate = new Date().toISOString();
      let newQrCode = {
        date: currentDate,
        brCode: brCode,
        pixKey: value.pixKey,
        merchantName: value.merchantName,
        transactionAmount: value.transactionAmount,
        infoAdicional: value.infoAdicional,
        pixType: value.pixKeyType,
      };
      qrcodesList = [newQrCode, ...qrcodesList].slice(0, 5);
      localStorage.setItem('QRCodes', JSON.stringify(qrcodesList));

      setQrValue(brCode);
      setShowInput(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSubmit(onSubmit)();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(qrValue);
  };

  const handleReset = () => {
    setValue('pixKey', '');
    setValue('merchantName', '');
    setValue('transactionAmount', '');
    setValue('infoAdicional', '');
    setQrValue('');
    setShowInput(true);
  };

  // const handleShare = () => {};

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bgcolor: bgcolor,
          color: textColor,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          transition: '0.3s ease-in-out',
        }}
      >
        <Stack
          direction={'row'}
          sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
        >
          <IconButton
            color="inherit"
            aria-label="QRCode"
            onClick={() => {
              setOpenQrcodeModal(true);
            }}
          >
            <FaQrcode />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="Switch dark/light mode"
            onClick={toggleMode}
          >
            {theme.palette.type === 'dark' ? <FaSun /> : <FaMoon />}
          </IconButton>
        </Stack>
        <IconButton
          sx={{ position: 'absolute', top: '1rem', left: '1rem' }}
          color="inherit"
          aria-label="historic"
          onClick={() => {
            navigate('/historic');
          }}
        >
          {<FaHistory />}
          <Typography
            sx={{
              fontSize: { xs: '0.75rem', md: '1rem', lg: '1.25rem' },
              fontWeight: 'bold',
              background: textColor,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              marginLeft: '0.5rem',
            }}
          >
            Histórico
          </Typography>
        </IconButton>

        {showInput && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: '100vh' }}
          >
            <Grid spacing={5} item xs={12} sm={8} md={6}>
              <Typography
                variant="h2"
                align="center"
                sx={{
                  fontSize: { xs: '1.50rem', md: '2rem', lg: '3rem' },
                  fontWeight: 'bold',
                  background: 'linear-gradient(to right, teal, green)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  marginBottom: '0.5rem',
                }}
              >
                Gerador de QR Code Pix
              </Typography>
              <Typography
                variant="h5"
                align="center"
                sx={{
                  fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' },
                  marginBottom: '1rem',
                }}
              >
                Insira os dados do Pix
              </Typography>
              <Stack
                spacing={2}
                alignItems="center"
                width="100%"
                sx={{
                  margin: 'auto',
                  width: 'fit-content',
                }}
              >
                <Controller
                  name="pixKey"
                  rules={{
                    required: 'Campo obrigatório',
                    validate: (value) => {
                      const pixKeyType = getValues('pixKeyType');

                      if (
                        pixKeyType === PixKeyTypes.CPF &&
                        !handlePixKeyChange(value, pixKeyType)
                      ) {
                        return 'CPF inválido';
                      } else if (
                        pixKeyType === PixKeyTypes.CNPJ &&
                        !handlePixKeyChange(value, pixKeyType)
                      ) {
                        return 'CNPJ inválido';
                      } else if (
                        pixKeyType === PixKeyTypes.PHONE &&
                        !handlePixKeyChange(value, pixKeyType)
                      ) {
                        return 'Telefone inválido';
                      } else if (
                        pixKeyType === PixKeyTypes.EVP &&
                        !handlePixKeyChange(value, pixKeyType)
                      ) {
                        return 'Chave aleatória inválida';
                      } else if (
                        pixKeyType === PixKeyTypes.EMAIL &&
                        !handlePixKeyChange(value, pixKeyType)
                      ) {
                        return 'Email inválido';
                      }

                      return true;
                    },
                  }}
                  control={control}
                  render={({ field, formState: { errors } }) => (
                    <FormControl
                      variant="outlined"
                      error={!!errors.pixKey}
                      fullWidth
                      sx={{
                        background: 'transparent',
                      }}
                    >
                      <InputLabel htmlFor="pixKey">Chave Pix</InputLabel>
                      <OutlinedInput
                        sx={{
                          background: 'transparent',
                        }}
                        autoFocus
                        id="pixKey"
                        type={'text'}
                        autoComplete="off"
                        {...field}
                        startAdornment={
                          <InputAdornment position="start">
                            <Box mr={2}>
                              <Controller
                                name="pixKeyType"
                                defaultValue="CPF"
                                rules={{ required: 'Campo obrigatório' }}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    variant="standard"
                                    {...field}
                                    autoWidth
                                  >
                                    {Object.entries(PixKeyTypes).map(
                                      ([key, name]) => (
                                        <MenuItem key={key} value={key}>
                                          {name}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                )}
                              />
                            </Box>
                          </InputAdornment>
                        }
                        label="Chave Pix"
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name="merchantName"
                  rules={{ required: 'Campo obrigatório' }}
                  control={control}
                  render={({ field, formState: { errors } }) => (
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={!!errors.merchantName}
                    >
                      <InputLabel htmlFor="merchantName">Nome</InputLabel>
                      <OutlinedInput
                        sx={{
                          color: textColor,
                        }}
                        autoComplete="off"
                        id="merchantName"
                        {...field}
                        startAdornment={
                          <InputAdornment position="start">
                            <FaUser />
                          </InputAdornment>
                        }
                        label="Nome"
                      />
                      <FormHelperText>
                        {errors.merchantName?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
                <Controller
                  name="transactionAmount"
                  rules={{ required: 'Campo obrigatório' }}
                  control={control}
                  render={({ field, formState: { errors } }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={!!errors.transactionAmount}
                    >
                      <InputLabel htmlFor="transactionAmount">
                        Valor da Transação
                      </InputLabel>
                      <OutlinedInput
                        autoComplete="off"
                        id="transactionAmount"
                        {...field}
                        startAdornment={
                          <InputAdornment position="start">
                            <FaMoneyBillWave />
                          </InputAdornment>
                        }
                        label="Valor da Transação"
                        onChange={(event) => {
                          const value = event.target.value;
                          const formattedValue = maskCurrency(
                            Number(value.replace(/\D/g, '')) / 100
                          );
                          field.onChange(formattedValue);
                        }}
                      />
                      <FormHelperText>
                        {errors.transactionAmount?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
                <Controller
                  name="infoAdicional"
                  control={control}
                  render={({ field, formState: { errors } }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={!!errors.infoAdicional}
                    >
                      <InputLabel htmlFor="infoAdicional">
                        Informação Adicional
                      </InputLabel>
                      <OutlinedInput
                        autoComplete="off"
                        id="infoAdicional"
                        {...field}
                        startAdornment={
                          <InputAdornment position="start">
                            <FaRegCommentDots />
                          </InputAdornment>
                        }
                        label="Informação Adicional"
                      />
                    </FormControl>
                  )}
                />
                <Button
                  variant="contained"
                  color={'primary'}
                  size={'large'}
                  sx={{ width: '100%' }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Gerar QRCode
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}

        {!showInput && (
          <Stack direction={'column'} sx={{ justifyContent: 'center' }}>
            <Typography
              variant="h2"
              align="center"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '2rem', md: '3rem', lg: '4rem' },
                background: 'linear-gradient(to right, teal, green)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                marginBottom: '1rem',
              }}
            >
              QR Code Pix
            </Typography>
            <Typography
              variant="h5"
              align="center"
              sx={{
                marginBottom: '1rem',
              }}
            >
              {getValues('transactionAmount')}
            </Typography>
            <Stack direction={'row'} sx={{ justifyContent: 'center' }}>
              <Box
                padding={2}
                bgcolor={'white'}
                sx={{
                  border: '1px solid black',
                  borderRadius: '8px',
                }}
              >
                <QRCode
                  size={200}
                  value={qrValue}
                  fgColor={qrColor}
                  imageSettings={{
                    src: imgSrc,
                    height: 36,
                    width: 36,
                    excavate: true,
                  }}
                />
              </Box>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
              }}
            >
              <IconButton
                color="primary"
                sx={{
                  borderRadius: '8px',
                  border: '1px solid black',
                  fontSize: '0.75rem',
                  paddingX: '1rem',
                }}
                size="small"
                aria-label="Copiar QRCode"
                onClick={handleCopy}
              >
                Copia e cola
                <FaCopy />
              </IconButton>
              {/* <IconButton
                color="primary"
                aria-label="Compartilhar QRCode"
                onClick={handleShare}
              >
                <FaShare />
              </IconButton> */}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button variant="contained" color="primary" onClick={handleReset}>
                Gerar outro QRCode
              </Button>
            </Box>
          </Stack>
        )}
        <QRCodeModal open={openQrcodeModal} onClose={handleClose} />
      </Box>
      <Footer />
    </ThemeProvider>
  );
};
