import { Email, GitHub, LinkedIn } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';

export const Footer = () => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        bottom: '2px',
        position: 'absolute',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <Stack spacing={0} alignItems="center">
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'bold',
            background: 'linear-gradient(to right, teal, green)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Cristian Henz Krein
        </Typography>

        <Typography variant="caption" color={'#737373'}>
          Software Developer
        </Typography>

        <Divider
          orientation="horizontal"
          flexItem
          sx={{
            background:
              'linear-gradient(to right, transparent, black, transparent)',
            height: '1px',
            width: { xs: '80%', sm: '50%' },
            margin: '2px auto',
          }}
        />

        <Stack direction="row" spacing={0} alignItems="center">
          <Link
            href="https://br.linkedin.com/in/cristian-henz-krein-9a0a45190"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton color="primary" aria-label="LinkedIn">
              <LinkedIn />
            </IconButton>
          </Link>

          <Link
            href="https://github.com/cristiankrein"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton color="primary" aria-label="GitHub">
              <GitHub />
            </IconButton>
          </Link>

          <Link href="mailto:cristian.krein@outlook.com">
            <IconButton color="primary" aria-label="Email">
              <Email />
            </IconButton>
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
};
