import { ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useTheme from './app/hook/useTheme';
import { QRCodeHistory } from './app/pages/pix/QRCodeHistory';
import { QRGenerator } from './app/pages/pix/QRGenerator';

function App() {
  const { theme } = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<QRGenerator />} />
          <Route path="/historic" element={<QRCodeHistory />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
