import { Modal, TextField } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import {
  Box,
  Button,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import useTheme from '../../hook/useTheme';

interface QRCodeModalProps {
  open: boolean;
  onClose: () => void;
}

const QRCodeModal: React.FC<QRCodeModalProps> = ({ open, onClose }) => {
  const { theme } = useTheme();
  const initialColor = localStorage.getItem('qrColor') || '#000000';
  const [color, setColor] = useState<string>(initialColor);
  const [imgSrc, setImgSrc] = useState<string>('');

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const bgcolor = theme.palette.type === 'dark' ? '#282c34' : '#f2f2f2';
  const textColor = theme.palette.type === 'dark' ? '#f2f2f2' : '#000';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = event.target.value;
    setColor(newColor);
    localStorage.setItem('qrColor', newColor);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const imgUrl = URL.createObjectURL(file);
    setImgSrc(imgUrl);
    localStorage.setItem('qrImage', imgUrl);
  };

  useEffect(() => {
    const savedImg = localStorage.getItem('qrImage');
    if (savedImg) setImgSrc(savedImg);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={open}
        onClose={onClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: isMobile ? '1rem' : 'auto',
          marginRight: isMobile ? '1rem' : 'auto',
        }}
      >
        <Stack
          sx={{
            backgroundColor: bgcolor,
            padding: '20px',
            borderRadius: '8px',
            position: 'relative',
          }}
          spacing={3}
          direction="column"
          alignItems="center"
        >
          <IconButton
            onClick={onClose}
            aria-label="Close QRCode Modal"
            sx={{ position: 'absolute', right: 10, top: 10 }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant="body1"
            align="center"
            sx={{
              fontSize: { xs: '1.50rem', md: '2.25rem', lg: '3rem' },
              fontWeight: 'bold',
              background: 'linear-gradient(to right, teal, green)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              marginBottom: '1rem',
            }}
          >
            Personalize seu QRCode
          </Typography>
          <Box
            padding={2}
            bgcolor={'white'}
            sx={{
              border: '1px solid black',
              borderRadius: '8px',
            }}
          >
            <QRCode
              value="https://krein.dev"
              fgColor={color}
              imageSettings={{
                src: imgSrc,
                height: 24,
                width: 24,
                excavate: true,
              }}
            />
          </Box>

          <TextField
            label="Alterar cor do QRCode"
            value={color}
            onChange={handleChange}
          />
          <Typography
            variant="body2"
            sx={{
              color: textColor,
            }}
          >
            O botão abaixo permite que você importe uma imagem que será
            utilizada no seu QR Code.
          </Typography>
          <Button
            variant="contained"
            component="label"
            sx={{
              background: 'linear-gradient(to right, teal, green)',
              color: '#fff',
            }}
          >
            Escolha uma imagem
            <input
              type="file"
              hidden
              onChange={handleImageUpload}
              accept="image/*"
            />
          </Button>
        </Stack>
      </Modal>
    </ThemeProvider>
  );
};

export default QRCodeModal;
