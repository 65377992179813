import { PaletteType } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

const createTheme = (mode: PaletteType) =>
  createMuiTheme({
    palette: {
      type: mode,
      primary: {
        main: mode === 'dark' ? '#ffffff' : '#000000',
      },
      text: {
        primary: mode === 'dark' ? '#ffffff' : '#000000',
      },
      background: {
        default: mode === 'dark' ? '#282c34' : '#f2f2f2',
      },
    },
  });

function useTheme() {
  useEffect(() => {
    let storedColorMode = localStorage.getItem('ColorMode');
    if (storedColorMode) setMode(storedColorMode as PaletteType);
  }, []);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [mode, setMode] = useState<PaletteType>(
    prefersDarkMode ? 'dark' : 'light'
  );

  const theme = createTheme(mode);

  return { theme, mode, setMode };
}

export default useTheme;
