export const maskCpf = (cpf: string) => {
  if (cpf.length < 11) {
    return cpf;
  } else if (cpf.length === 11) {
    return (
      cpf.substring(0, 3) +
      '.' +
      cpf.substring(3, 6) +
      '.' +
      cpf.substring(6, 9) +
      '-' +
      cpf.substring(9, 11)
    );
  }
};

export const maskCnpj = (cnpj: string): string => {
  if (cnpj.length === 14) {
    return (
      cnpj.substring(0, 2) +
      '.' +
      cnpj.substring(2, 5) +
      '.' +
      cnpj.substring(5, 8) +
      '/' +
      cnpj.substring(8, 12) +
      '-' +
      cnpj.substring(12, 14)
    );
  } else {
    return 'Invalid CNPJ';
  }
};

export const maskPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber.length === 11) {
    return (
      '(' +
      phoneNumber.substring(0, 2) +
      ') ' +
      phoneNumber.substring(2, 7) +
      '-' +
      phoneNumber.substring(7, 11)
    );
  } else {
    return 'Invalid phone number';
  }
};

export const onlyNumbers = (value: string): string => {
  return value.replace(/\D/g, '');
};
