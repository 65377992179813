import { FileCopy } from '@mui/icons-material';
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
  styled,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';
import { FaArrowLeft, FaMoon, FaSun } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import useTheme from '../../hook/useTheme';
import { PixKeyTypes } from '../../types/pixKeyTypes';
import { maskCnpj, maskCpf, maskPhoneNumber } from '../../utils/TextParser';

interface QRCodeData {
  date: string;
  brCode: string;
  pixKey: string;
  merchantName: string;
  transactionAmount: string;
  infoAdicional: string;
  pixType: string;
}

export const QRCodeHistory = () => {
  const { theme, setMode, mode } = useTheme();
  const [qrCodes, setQrCodes] = useState<QRCodeData[]>([]);
  const [imgSrc, setImgSrc] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const qrColor = localStorage.getItem('qrColor') || '#000000';
  const bgcolor = theme.palette.type === 'dark' ? '#282c34' : '#f2f2f2';
  const textColor = theme.palette.type === 'dark' ? '#f2f2f2' : '#000';

  const navigate = useNavigate();
  const toggleMode = () => {
    localStorage.setItem('ColorMode', mode === 'light' ? 'dark' : 'light');
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    let storedQRCodes = localStorage.getItem('QRCodes');
    setQrCodes(storedQRCodes ? JSON.parse(storedQRCodes) : []);
    setLoading(false);
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCopy = (brCode: string) => {
    navigator.clipboard.writeText(brCode);
  };

  useEffect(() => {
    const savedImg = localStorage.getItem('qrImage');
    if (savedImg) setImgSrc(savedImg);
  }, []);

  const ResponsiveBox = styled(Box)`
    @media (max-width: 600px) {
      flex-direction: column;
    }
  `;

  const ResponsiveCard = styled(Card)`
    @media (max-width: 600px) {
      padding-top: 1rem;
    }
  `;

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: bgcolor,
          color: textColor,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: isMobile ? undefined : '100vh',
          transition: '0.3s ease-in-out',
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: '1rem', right: '1rem', zIndex: 1 }}
          color="inherit"
          aria-label="Switch dark/light mode"
          onClick={toggleMode}
        >
          {theme.palette.type === 'dark' ? <FaSun /> : <FaMoon />}
        </IconButton>

        <IconButton
          sx={{ position: 'absolute', top: '1rem', left: '1rem', zIndex: 1 }}
          color="inherit"
          aria-label="QRGenerator"
          onClick={() => {
            navigate('/');
          }}
        >
          {<FaArrowLeft />}
          <Typography
            sx={{
              fontSize: { xs: '0.75rem', md: '1rem', lg: '1.25rem' },
              fontWeight: 'bold',
              background: textColor,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              marginLeft: '0.5rem',
            }}
          >
            Voltar
          </Typography>
        </IconButton>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            top: '1rem',
            position: 'relative',
          }}
        >
          <Typography
            variant="h2"
            align="center"
            marginTop={isMobile ? '3rem' : '0'}
            sx={{
              fontSize: {
                xs: '1.5rem',
                sm: '2rem',
                md: '3rem',
                lg: '4rem',
                xl: '4.5rem',
              },
              fontWeight: 'bold',
              background: 'linear-gradient(to right, teal, green)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              marginBottom: '1rem',
            }}
          >
            {loading
              ? 'Carregando QRCodes...'
              : qrCodes.length === 0
              ? 'Você não possui QR Codes gerados :('
              : 'Histórico de QR Codes gerados'}
          </Typography>
        </Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <ResponsiveBox
            sx={{
              width: '90%',
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
              margin: '2rem',
            }}
          >
            {qrCodes.map((qrCode, index) => (
              <ResponsiveCard
                key={index}
                sx={{
                  width: isMobile ? '90%' : '18%',
                  margin: isMobile ? '1rem 0' : '1rem 0',
                  padding: '0.50rem',
                  backgroundColor: '#fff',
                  borderRadius: '10px',
                  boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
                  backgroundImage: 'linear-gradient(to right, teal, green)',
                  p: isMobile ? '1rem' : '0.5rem',
                }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Typography variant="body2" color="textSecondary">
                    {moment(qrCode.date).format('DD/MM/YYYY HH:mm')}
                  </Typography>
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={() => handleCopy(qrCode.brCode)}
                  >
                    <FileCopy fontSize="inherit" />
                  </IconButton>
                </Stack>

                <Stack direction="column" spacing={-1}>
                  <Typography
                    color={'black'}
                    fontSize={'1rem'}
                    variant="button"
                  >
                    Nome:
                  </Typography>
                  <Typography
                    color={'white'}
                    variant="caption"
                    whiteSpace={'nowrap'}
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                  >
                    {qrCode.merchantName}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={-1}>
                  <Typography
                    color={'black'}
                    fontSize={'1rem'}
                    variant="button"
                  >
                    Chave: ({qrCode.pixType})
                  </Typography>
                  <Typography
                    color={'white'}
                    variant="caption"
                    whiteSpace={'nowrap'}
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                  >
                    {qrCode.pixType === PixKeyTypes.CPF
                      ? maskCpf(qrCode.pixKey)
                      : qrCode.pixType === PixKeyTypes.CNPJ
                      ? maskCnpj(qrCode.pixKey)
                      : qrCode.pixType === PixKeyTypes.PHONE
                      ? maskPhoneNumber(qrCode.pixKey)
                      : qrCode.pixKey}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={-1}>
                  <Typography
                    color={'black'}
                    fontSize={'1rem'}
                    variant="button"
                  >
                    Valor:
                  </Typography>
                  <Typography
                    color={'white'}
                    variant="caption"
                    whiteSpace={'nowrap'}
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                  >
                    {qrCode.transactionAmount}
                  </Typography>
                </Stack>

                {qrCode.infoAdicional ? (
                  <>
                    <Stack direction="column" spacing={-1}>
                      <Typography
                        color={'black'}
                        variant="button"
                        fontWeight={'bold'}
                      >
                        Descrição:
                      </Typography>
                      <Typography
                        color={'white'}
                        variant="caption"
                        whiteSpace={'nowrap'}
                        overflow={'hidden'}
                        textOverflow={'ellipsis'}
                      >
                        {qrCode.infoAdicional}
                      </Typography>
                    </Stack>
                  </>
                ) : (
                  <Stack direction="column" spacing={-1}>
                    <Typography
                      color={'black'}
                      variant="button"
                      fontWeight={'bold'}
                    >
                      Descrição:
                    </Typography>
                    <Typography color={'white'} variant="caption">
                      Não informado
                    </Typography>
                  </Stack>
                )}

                <Stack
                  paddingTop={1}
                  direction="row"
                  sx={{ justifyContent: 'center' }}
                >
                  <Box
                    padding={1}
                    bgcolor={'white'}
                    sx={{
                      border: '1px solid black',
                      borderRadius: '8px',
                    }}
                  >
                    <QRCode
                      value={qrCode.brCode}
                      fgColor={qrColor}
                      imageSettings={{
                        src: imgSrc,
                        height: 24,
                        width: 24,
                        excavate: true,
                      }}
                    />
                  </Box>
                </Stack>
              </ResponsiveCard>
            ))}
          </ResponsiveBox>
        )}
      </Box>
    </ThemeProvider>
  );
};
