import { PixKeyTypes } from '../types/pixKeyTypes';

const validarCPF = (cpf: string): boolean => {
  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf.length !== 11) {
    return false;
  }

  if (/^(\d)\1+$/.test(cpf)) {
    return false;
  }

  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let resto = (soma * 10) % 11;
  let digitoVerificador1 = resto === 10 ? 0 : resto;

  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * (11 - i);
  }
  resto = (soma * 10) % 11;
  let digitoVerificador2 = resto === 10 ? 0 : resto;

  if (
    parseInt(cpf.charAt(9)) !== digitoVerificador1 ||
    parseInt(cpf.charAt(10)) !== digitoVerificador2
  ) {
    return false;
  }

  return true;
};

const validarCNPJ = (cnpj: string): boolean => {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj.length !== 14) {
    return false;
  }

  if (/^(\d)\1+$/.test(cnpj)) {
    return false;
  }

  let soma = 0;
  let peso = 2;
  for (let i = 11; i >= 0; i--) {
    soma += parseInt(cnpj.charAt(i)) * peso;
    peso = peso === 9 ? 2 : peso + 1;
  }
  let resto = soma % 11;
  let digitoVerificador1 = resto < 2 ? 0 : 11 - resto;

  soma = 0;
  peso = 2;
  for (let i = 12; i >= 0; i--) {
    soma += parseInt(cnpj.charAt(i)) * peso;
    peso = peso === 9 ? 2 : peso + 1;
  }
  resto = soma % 11;
  let digitoVerificador2 = resto < 2 ? 0 : 11 - resto;

  if (
    parseInt(cnpj.charAt(12)) !== digitoVerificador1 ||
    parseInt(cnpj.charAt(13)) !== digitoVerificador2
  ) {
    return false;
  }

  return true;
};

export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  console.log(phoneNumber);
  const regex = /^(\(\d{2}\)\s?|\d{2}\s?)?\d{4,5}-?\d{4}$/;
  return regex.test(phoneNumber);
};

export const handlePixKeyChange = (value: string, type: string) => {
  switch (type) {
    case PixKeyTypes.CPF:
      return validarCPF(value);
    case PixKeyTypes.CNPJ:
      return validarCNPJ(value);
    case PixKeyTypes.PHONE:
      return isValidPhoneNumber(value);
    case PixKeyTypes.EVP:
      return value.match(
        /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/
      );
    case PixKeyTypes.EMAIL:
      return value.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      );

    default:
      break;
  }
};
